<template>
  <grid item-min-width="400px">
    <component
      class="grid-component"
      v-if="computedActionType"
      :is="computedComponentParams"
      :document.sync="computedContentParams"
      :dense="dense"
      ref="component"
    />
  </grid>
</template>

<script>
export default {
  components: {
    AssignToWorkQueue: () => import('./actions/assign-to-work-queue'),
    CreateWorkItem: () => import('./actions/create-work-item'),
    Delete: () => import('./actions/delete'),
    Grid: () => import('@/components/grid'),
    SendEMail: () => import('./actions/send-e-mail'),
    SetLabelsLink: () => import('./actions/set-labels-link'),
    UpdateGroupamaCreditStatus: () => import('./actions/update-groupama-credit-status'),
    UpdateGroupamaStandingCreditLimit: () => import('./actions/update-groupama-standing-credit-limit'),
    UpdateCreditStatus: () => import('./actions/update-credit-status'),
    UpdateCreditLimit: () => import('./actions/update-credit-limit'),
    UpdateTopicStatus: () => import('./actions/update-topic-status.vue')
  },
  computed: {
    computedActionType: {
      get () { return this.dataActionType },
      set (v) {
        this.dataActionType = v
        this.$emit('update:action-type', this.dataActionType)
      }
    },
    computedContentParams: {
      get () {
        return this.dataContent
      },
      set (v) {
        this.dataContent = v
        this.dataDocumentEmit()
      }
    },
    computedComponentParams () {
      return this.toPascalCase(this.computedActionType)
    }
  },
  data () {
    return {
      required: [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')],
      dataActionType: null,
      dataContent: null,
      dataActionItems: [],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  inheritAttrs: false,
  methods: {
    emitDocument () {
      if (this.viewType && this.dataContent) {
        const doc = { type: this.viewType, c: this.dataContent }
        if (!this.lodash.isEqual(this.document, doc)) {
          this.$emit('update:document', this.lodash.cloneDeep(doc))
        }
      }
    }
  },
  props: {
    actionType: String,
    document: Object,
    viewType: String,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataContent = v?.c ? this.lodash.cloneDeep(v.c) : {}
        this.computedActionType = v?.c?.type
        this.dataDocumentEmit()
      },
      immediate: true
    },
    actionType: {
      immediate: true,
      handler (v) {
        if (v) {
          this.computedActionType = v
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.grid-component
  display contents

.action-types
  max-width 800px
</style>
